import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import moment from 'moment'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
// import ModalSubmitWarning from './ModalSubmitWarning'
import ModalSubscriptionStatusChange from './ModalSubscriptionStatusChange'
import ModalHistory from './ModalHistory'
import {
  fetchDataSyncHistoryData,
  fetchSubscriptionHistoryData,
  syncDataFromOrcaApp,
} from '../../services/api'

export default function ModalApplicationDetail(props) {
  const { settings } = props
  const {
    data,
    handleUpdateSubscription,
    applicationDetailModalShow,
    handleModalClose,
    handleSaveRemarks,
  } = settings
  // 使用 useState 來管理備註內容
  const [remarks, setRemarks] = useState(data.app_remark || '')

  // 控制訂閱狀態歷史紀錄模態框
  const [subscriptionHistoryModalShow, setSubscriptionHistoryModalShow] =
    useState(false)

  // 控制資料同步歷史紀錄模態框
  const [dataSyncHistoryModalShow, setDataSyncHistoryModalShow] =
    useState(false)

  const handleRemarksChange = (event) => {
    setRemarks(event.target.value)
  }

  useEffect(() => {
    setRemarks(data.app_remark || '')
  }, [data])

  const [
    subscriptionStatusChangeModalShow,
    setSubscriptionStatusChangeModalShow,
  ] = useState(false)
  const handleSubscriptionStatusChangeModalShow = () => {
    setSubscriptionStatusChangeModalShow(true)
  }

  const handleSubscriptionStatusChangeModalClose = (res) => {
    console.log('res', res)
    if (res) {
      handleUpdateSubscription(
        !res.error
          ? {
              subscription_status: res.data.new_status,
              app_expiration_time: moment(res.data.expiration_time).format(
                'YYYY-MM-DD HH:mm:ss'
              ),
              api_status: res.status,
              api_message: res.message,
            }
          : {
              error_status: res.error_status,
              error: res.error,
            }
      )
      // do something
    }
    setSubscriptionStatusChangeModalShow(false)
  }

  const [syncOption, setSyncOption] = useState('single') // "single" 或 "range"
  const [syncMonth, setSyncMonth] = useState(moment().format('YYYYMM'))
  const [syncStart, setSyncStart] = useState(moment().format('YYYYMM'))
  const [syncEnd, setSyncEnd] = useState(
    moment().add(1, 'months').format('YYYYMM')
  )

  // 建立 24 個月的選項（包含當月）
  const monthOptions = []
  for (let i = 23; i >= 0; i -= 1) {
    monthOptions.push(moment().subtract(i, 'months').format('YYYYMM'))
  }

  // 同步 API 狀態回應與載入狀態
  const [syncing, setSyncing] = useState(false)
  const [syncMessage, setSyncMessage] = useState(null)

  const handleSyncClick = async () => {
    setSyncing(true)
    setSyncMessage(null)
    try {
      // 組合 API 傳入參數
      const params = { shop: data.oca_name }
      if (syncOption === 'single') {
        params.month = syncMonth
      } else {
        params.start = syncStart
        params.end = syncEnd
      }
      const response = await syncDataFromOrcaApp(params)

      // 檢查 response 狀態 (根據 apiAuthInstance 的回傳格式)
      if (response && response.status >= 200 && response.status < 300) {
        setSyncMessage(
          '呼叫ORCA APP資料同步請求成功（實際同步狀態至ORCA APP查看）'
        )
      } else {
        setSyncMessage(
          `同步失敗：${
            (response && response.data && response.data.message) || '未知錯誤'
          }`
        )
      }
    } catch (err) {
      setSyncMessage(`同步錯誤：${err.message}`)
    } finally {
      setSyncing(false)
    }
  }

  useEffect(() => {
    if (dataSyncHistoryModalShow) {
      console.log(
        'Fetching data sync history data for client_application_id:',
        data.id
      )

      fetchDataSyncHistoryData(data.id)
        .then((response) => {
          console.log('Data sync history API response:', response)
          console.log('Data sync history API data:', response.data)
        })
        .catch((error) => {
          console.log('Error fetching data sync history:', error)
        })
    }
  }, [dataSyncHistoryModalShow, data.id])

  return (
    <>
      {/* 特定工單 */}
      <Modal show={applicationDetailModalShow} size="xl">
        <Modal.Header closeButton onHide={handleModalClose}>
          <Modal.Title className="px-4">{data.oca_name} 的詳細資料</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex px-5">
          <Row className="d-flex h-100 w-100 m-0">
            {/* Applicant Information */}
            <Col
              xs={5}
              className="pt-2 d-flex flex-column px-1 text-orcaDarker lh-md"
            >
              {/* <h5 className="text-orcaMid fw-bolder">詳細資料</h5> */}
              <div className="lh-sm pb-2">
                <Row className="pt-2">
                  <Col xs={4}>工單ID:</Col>
                  <Col xs={8} className="text-end">
                    {data.id}
                  </Col>
                </Row>
                <Row className="pt-2">
                  <Col xs={4}>工單建立時間:</Col>
                  <Col xs={8} className="text-end">
                    {data.create_time
                      .toString()
                      .split('.')[0]
                      .replace('T', ' ')}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>到期時間:</Col>
                  <Col xs={8} className="text-end">
                    {data.expired_time
                      .toString()
                      .split('.')[0]
                      .replace('T', ' ')}
                  </Col>
                </Row>
              </div>

              <div className="lh-sm pb-2">
                <Row className="pt-2">
                  <Col xs={4}>公司名稱:</Col>
                  <Col xs={8} className="text-end">
                    {data.company_name}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>統一編號:</Col>
                  <Col xs={8} className="text-end">
                    {data.tax_id}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>聯絡電話:</Col>
                  <Col xs={8} className="text-end">
                    {data.contact_number ? data.contact_number : '---'}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>聯絡信箱:</Col>
                  <Col xs={8} className="text-end">
                    {data.applicant ? data.applicant : '---'}
                  </Col>
                </Row>
              </div>
              <div className="lh-sm py-2">
                <Row>
                  <Col xs={4}>資料來源:</Col>
                  <Col xs={8} className="text-end">
                    {data.data_src}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>申請來源:</Col>
                  <Col xs={8} className="text-end">
                    {data.application_src}
                  </Col>
                </Row>
              </div>
              <div className="lh-sm py-2">
                <Row>
                  <Col xs={4}>APP安裝時間:</Col>
                  <Col xs={8} className="text-end">
                    {data.installation_time}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>資料同步時間:</Col>
                  <Col xs={8} className="text-end">
                    {data.data_sync_completion_time}
                  </Col>
                </Row>
                <Button
                  variant="orcaLight"
                  onClick={() => setDataSyncHistoryModalShow(true)}
                >
                  查看資料同步歷史紀錄
                </Button>
              </div>
              {/* 新增「資料同步」區段 */}
              <div className="lh-sm py-2 border-top pt-3">
                <h5 className="mb-2">資料同步設定</h5>
                <div className="mb-2">
                  <label
                    className="form-check-label"
                    htmlFor="sync-option-single"
                  >
                    <input
                      id="sync-option-single"
                      type="radio"
                      name="syncOption"
                      className="form-check-input"
                      value="single"
                      checked={syncOption === 'single'}
                      onChange={(e) => setSyncOption(e.target.value)}
                    />
                    單個年月
                  </label>
                  <label
                    className="form-check-label"
                    htmlFor="sync-option-range"
                  >
                    <input
                      id="sync-option-range"
                      type="radio"
                      name="syncOption"
                      className="form-check-input"
                      value="range"
                      checked={syncOption === 'range'}
                      onChange={(e) => setSyncOption(e.target.value)}
                    />
                    年月範圍
                  </label>
                </div>
                {syncOption === 'single' ? (
                  <div className="mb-2">
                    <select
                      value={syncMonth}
                      onChange={(e) => setSyncMonth(e.target.value)}
                      className="form-select form-select-sm"
                    >
                      {monthOptions.map((m) => (
                        <option key={m} value={m}>
                          {m}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <div className="d-flex align-items-center mb-2">
                    <select
                      value={syncStart}
                      onChange={(e) => {
                        const newStart = e.target.value
                        setSyncStart(newStart)
                        // 若當前結束月份比新開始月份還小，自動更新結束月份
                        if (newStart > syncEnd) {
                          setSyncEnd(newStart)
                        }
                      }}
                      className="form-select form-select-sm me-2"
                    >
                      {monthOptions.map((m) => (
                        <option key={m} value={m}>
                          {m}
                        </option>
                      ))}
                    </select>
                    <span className="me-2">到</span>
                    <select
                      value={syncEnd}
                      onChange={(e) => setSyncEnd(e.target.value)}
                      className="form-select form-select-sm"
                    >
                      {/* {monthOptions.map((m) => (
                        <option key={m} value={m}>
                          {m}
                        </option>
                      ))} */}
                      {monthOptions
                        .filter((m) => m >= syncStart)
                        .map((m) => (
                          <option key={m} value={m}>
                            {m}
                          </option>
                        ))}
                    </select>
                  </div>
                )}
                <Button
                  variant="orca"
                  onClick={handleSyncClick}
                  disabled={syncing}
                  className="mt-2"
                >
                  {syncing ? '同步中...' : '觸發資料同步'}
                </Button>
                {syncMessage && (
                  <div className="mt-2 small text-muted">{syncMessage}</div>
                )}
              </div>
              <div className="lh-sm py-2">
                <Row>
                  <Col xs={4}>訂閱狀態:</Col>
                  <Col xs={8} className="text-end">
                    {data.subscription_status}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>降版到期時間:</Col>
                  <Col xs={8} className="text-end">
                    {data.app_expiration_time}
                  </Col>
                </Row>
                <div className="d-flex justify-content-start my-3">
                  <Button
                    variant="orca"
                    className="me-2" // 增加按鈕之間的間距
                    onClick={handleSubscriptionStatusChangeModalShow} // 觸發狀態調整 Modal
                  >
                    調整訂閱狀態
                  </Button>
                  <ModalSubscriptionStatusChange
                    show={subscriptionStatusChangeModalShow}
                    onClose={handleSubscriptionStatusChangeModalClose}
                    clientApplicationId={data.id}
                  />
                  <Button
                    variant="orcaLight"
                    onClick={() => setSubscriptionHistoryModalShow(true)}
                  >
                    查看訂閱狀態歷史紀錄
                  </Button>
                </div>
              </div>
            </Col>

            {/* 必要資料  */}
            <Col
              xs={7}
              className="pt-2 d-flex flex-column px-3 text-orcaDarker lh-lg"
            >
              {/* <h5 className="text-orcaMid fw-bolder">---</h5> */}
              <div className="lh-lg ps-2">
                <div className="lh-sm py-2">
                  <Row>
                    <Col xs={4}>ORCA版本:</Col>
                    <Col xs={8} className="text-end">
                      {data.version}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={4}>OCA 名稱:</Col>
                    <Col xs={8} className="text-end">
                      {data.oca_name}
                    </Col>
                  </Row>
                </div>
                <div className="lh-sm py-2">
                  <Row>
                    <Col xs={5}>MA Compartment ID:</Col>
                    <Col xs={7} className="text-end">
                      {data.ma_comp_id}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={3}>SIB Key:</Col>
                    <Col xs={9} className="text-end">
                      {data.sib_key ? 'OK' : 'NONO'}
                    </Col>
                  </Row>
                </div>
                <div className="lh-sm py-2">
                  <Row>
                    <Col xs={3}>OCA URL</Col>
                    <Col xs={9} className="text-end">
                      {data.orca_analytics_url}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={3}>OCM URL</Col>
                    <Col xs={9} className="text-end">
                      {data.orca_ma_url}
                    </Col>
                  </Row>
                </div>
                <div className="lh-sm py-2">
                  <Row>
                    <Col xs={3}>Language</Col>
                    <Col xs={9} className="text-end">
                      {data.comp_default_language}
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col
              xs={7}
              className="pt-2 d-flex flex-column px-3 text-orcaDarker lh-lg"
            >
              {/* ... 其他内容 ... */}
              <div className="lh-sm py-2">
                <Row>
                  <Col xs={3}>備註</Col>
                  <Col xs={9} className="text-end">
                    <textarea
                      className="form-control"
                      value={remarks}
                      onChange={handleRemarksChange}
                      rows="4"
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-center">
          <Button
            variant="secondary"
            name="apiStatus"
            onClick={handleModalClose}
          >
            取消/關閉
          </Button>
          <Button
            variant="orca"
            name="saveRemarks"
            onClick={() => {
              handleSaveRemarks(remarks) // 儲存備註
              handleModalClose() // 關閉 Modal
            }}
          >
            儲存備註並關閉
          </Button>
        </Modal.Footer>
      </Modal>

      {/* 結束 Modal */}

      {/* 訂閱狀態歷史紀錄 Modal */}
      <ModalHistory
        show={subscriptionHistoryModalShow}
        handleClose={() => setSubscriptionHistoryModalShow(false)}
        apiToCall={fetchSubscriptionHistoryData} // 傳入 API 函數
        client_application_id={data.id} // 傳入對應的參數
        title="訂閱狀態歷史紀錄"
        tableConfig={[
          {
            head: '變更時間',
            fieldName: 'changed_at',
            dataType: 'datetime',
          },
          {
            head: '舊訂閱狀態',
            fieldName: 'previous_status',
            dataType: 'text',
          },
          { head: '新訂閱狀態', fieldName: 'new_status', dataType: 'text' },
          {
            head: '到期時間',
            fieldName: 'expiration_time',
            dataType: 'datetime',
          },
          { head: '原因', fieldName: 'reason', dataType: 'text' },
        ]}
      />

      {/* 資料同步歷史紀錄 Modal */}
      <ModalHistory
        show={dataSyncHistoryModalShow}
        handleClose={() => setDataSyncHistoryModalShow(false)}
        apiToCall={fetchDataSyncHistoryData} // 傳入 API 函數
        client_application_id={data.id} // 傳入對應的參數
        title="資料同步歷史紀錄"
        tableConfig={[
          { head: '同步時間', fieldName: 'sync_time', dataType: 'datetime' },
          { head: '同步狀態', fieldName: 'status' },
          { head: '詳細資訊', fieldName: 'message' },
        ]}
      />
    </>
  )
}
